<template>
  <div class="flex flex-col justify-between sm:flex-row sm:justify-start">
    <div class="order-2 mt-2 sm:order-1 sm:mr-6 sm:mt-0 lg:mr-12">
      <label
        class="mb-1 font-bold"
        for="captcha"
        v-html="data.captcha_input_code.label + '*'"
      />
      <FieldGenericInput
        v-model="modelVal.captcha_input_code"
        class="w-full text-g7-blue"
        name="captcha"
        :type="data.captcha_input_code.type"
        :validation="string().required(messageRequired)"
      />
    </div>
    <div class="order-1 flex sm:order-2">
      <FieldGenericInput
        v-model="modelVal.captcha_token"
        :name="data.captcha_token.name"
        :type="data.captcha_token.type"
      />
      <img
        class="sm:h-[4.5rem] sm:w-60"
        :src="`data:image/jpeg;base64,${image}`"
      />
      <div class="flex items-center">
        <IconsRepeat
          class="ml-4 w-10 cursor-pointer rounded-md bg-g7-blue fill-white p-2"
          @click="refresh"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { string } from "yup";
import type { CaptchaFormFields } from "~~/types/form";
import type { Captcha } from "~~/types/request";
import type { CaptchaResponse } from "~~/types/response";

const props = defineProps<{
  data: CaptchaFormFields;
  modelValue: Captcha;
}>();

const emit = defineEmits(["update:modelValue"]);

const modelVal = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});

const image = ref(props.data.captcha_image);
const token = ref(props.data.captcha_token.value);
modelVal.value.captcha_token = token.value;

async function refresh() {
  const response = await useGo7seasClient<CaptchaResponse>(`/catalog/captcha`);
  modelVal.value.captcha_token = response.token;
  image.value = response.img;
}
</script>
